import {
  Create,
  DateInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const TermsAndConditionsCreate = () => (
  <Create transform={transformInput}>
    <SimpleForm>
      <TextInput source="version" />
      <TextInput source="url" />
      <DateInput source="activationDate" />
    </SimpleForm>
  </Create>
);
