import {
  Datagrid,
  DateField,
  List,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

export const TermsAndConditionsList = () => (
  <List pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="version" />
      <TextField source="url" />
      <DateField source="activationDate" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
