import {
  Admin,
  Resource,
  fetchUtils,
  CustomRoutes,
  HttpError,
} from 'react-admin';
import { Route } from 'react-router-dom';
import simpleRestProvider from 'ra-data-simple-rest';
import { Auth } from 'aws-amplify';

import { Dashboard } from '../components/admin/Dashboard';
import { ClientList } from '../components/admin/client/ClientList';
import { ClientEdit } from '../components/admin/client/ClientEdit';
import { LocalCreate } from '../components/admin/local/LocalCreate';
import { LocalList } from '../components/admin/local/LocalList';
import { LocalEdit } from '../components/admin/local/LocalEdit';
import { CommuneList } from '../components/admin/commune/CommuneList';
import { CommuneEdit } from '../components/admin/commune/CommuneEdit';
import { CommuneCreate } from '../components/admin/commune/CommuneCreate';
import { RegionList } from '../components/admin/region/RegionList';
import { RegionEdit } from '../components/admin/region/RegionEdit';
import { RegionCreate } from '../components/admin/region/RegionCreate';
import { CountryList } from '../components/admin/country/CountryList';
import { CountryCreate } from '../components/admin/country/CountryCreate';
import { CountryEdit } from '../components/admin/country/CountryEdit';
import { ProductList } from '../components/admin/product/ProductList';
import { ProductCreate } from '../components/admin/product/ProductCreate';
import { ProductEdit } from '../components/admin/product/ProductEdit';
import { OrderDetailList } from '../components/admin/order-detail/OrderDetailList';
import { OrderDetailEdit } from '../components/admin/order-detail/OrderDetailEdit';
import { OrderDetailCreate } from '../components/admin/order-detail/OrderDetailCreate';
import { OrderList } from '../components/admin/order/OrderList';
import { OrderCreate } from '../components/admin/order/OrderCreate';
import { OrderEdit } from '../components/admin/order/OrderEdit';
import { OrderTypeList } from '../components/admin/order-type/OrderTypeList';
import { IntegrationList } from '../components/admin/integration/IntegrationList';
import { IntegrationCreate } from '../components/admin/integration/IntegrationCreate';
import { IntegrationEdit } from '../components/admin/integration/IntegrationEdit';
import { PointOfSaleList } from '../components/admin/point-of-sale/PointOfSaleList';
import { PointOfSaleEdit } from '../components/admin/point-of-sale/PointOfSaleEdit';
import { ClientLocalList } from '../components/admin/client-local/ClientLocalList';
import { ClientLocalCreate } from '../components/admin/client-local/ClientLocalCreate';
import { ClientLocalEdit } from '../components/admin/client-local/ClientLocalEdit';
import { StockMoveList } from '../components/admin/stock-move/StockMoveList';
import { StockMoveCreate } from '../components/admin/stock-move/StockMoveCreate';
import { StockMoveEdit } from '../components/admin/stock-move/StockMoveEdit';
import { ShipmentList } from '../components/admin/shipment/ShipmentList';
import { ShipmentCreate } from '../components/admin/shipment/ShipmentCreate';
import { ShipmentEdit } from '../components/admin/shipment/ShipmentEdit';
import { useTheme } from '@emotion/react';
import AdminLayout from '../components/admin/AdminLayout';
import Onboarding from './Onboarding';
import { PackComponentList } from '../components/admin/pack-component/PackComponentList';
import { PackComponentCreate } from '../components/admin/pack-component/PackComponentCreate';
import { PackComponentEdit } from '../components/admin/pack-component/PackComponentEdit';
import { ReceptionList } from '../components/admin/reception/ReceptionList';
import { ReceptionCreate } from '../components/admin/reception/ReceptionCreate';
import { ReceptionEdit } from '../components/admin/reception/ReceptionEdit';
import { ReceptionDetailList } from '../components/admin/reception-detail/ReceptionDetailList';
import { ReceptionDetailCreate } from '../components/admin/reception-detail/ReceptionDetailCreate';
import { ReceptionDetailEdit } from '../components/admin/reception-detail/ReceptionDetailEdit';
import { OpeningHourList } from '../components/admin/opening-hour/OpeningHourList';
import { OpeningHourCreate } from '../components/admin/opening-hour/OpeningHourCreate';
import { OpeningHourEdit } from '../components/admin/opening-hour/OpeningHourEdit';
import { PaymentList } from '../components/admin/billing/payments/PaymentList';
import { PaymentCreate } from '../components/admin/billing/payments/PaymentCreate';
import { PaymentEdit } from '../components/admin/billing/payments/PaymentEdit';
import Massive from './Massive/Massive';
import Webhooks from './Webhooks';
import POSLocals from './POSLocals';
import { PointOfSaleProductList } from '../components/admin/point-of-sale-product/PointOfSaleProductList';
import { PointOfSaleProductCreate } from '../components/admin/point-of-sale-product/PointOfSaleProductCreate';
import { PointOfSaleProductEdit } from '../components/admin/point-of-sale-product/PointOfSaleProductEdit';
import { ChargeRateList } from '../components/admin/charge-rate/ChargeRateList';
import { ChargeRateCreate } from '../components/admin/charge-rate/ChargeRateCreate';
import { ChargeRateEdit } from '../components/admin/charge-rate/ChargeRateEdit';
import { ChargeRateValueCreate } from '../components/admin/charge-rate-value/ChargeRateValueCreate';
import { ChargeRateValueEdit } from '../components/admin/charge-rate-value/ChargeRateValueEdit';
import { ChargeRateValueList } from '../components/admin/charge-rate-value/ChargeRateValueList';
import { UserCreate } from '../components/admin/user/UserCreate';
import { UserEdit } from '../components/admin/user/UserEdit';
import { UserList } from '../components/admin/user/UserList';
import { ConnectionCreate } from '../components/admin/connection/ConnectionCreate';
import { ConnectionEdit } from '../components/admin/connection/ConnectionEdit';
import { ConnectionList } from '../components/admin/connection/ConnectionList';
import { RoleCreate } from '../components/admin/role/RoleCreate';
import { RoleEdit } from '../components/admin/role/RoleEdit';
import { RoleList } from '../components/admin/role/RoleList';
import { PermissionCreate } from '../components/admin/permission/PermissionCreate';
import { PermissionEdit } from '../components/admin/permission/PermissionEdit';
import { PermissionList } from '../components/admin/permission/PermissionList';
import { RolePermissionCreate } from '../components/admin/role-permission/RolePermissionCreate';
import { RolePermissionEdit } from '../components/admin/role-permission/RolePermissionEdit';
import { RolePermissionList } from '../components/admin/role-permission/RolePermissionList';
import { ClientCourierCreate } from '../components/admin/client-courier/ClientCourierCreate';
import { ClientCourierEdit } from '../components/admin/client-courier/ClientCourierEdit';
import { ClientCourierList } from '../components/admin/client-courier/ClientCourierList';
import { DriverList } from '../components/admin/driver/DriverList';
import { DriverCreate } from '../components/admin/driver/DriverCreate';
import { DriverEdit } from '../components/admin/driver/DriverEdit';
import { JourneyCreate } from '../components/admin/journey/JourneyCreate';
import { JourneyEdit } from '../components/admin/journey/JourneyEdit';
import { JourneyList } from '../components/admin/journey/JourneyList';
import { ClientOrderCreate } from '../components/admin/client-order/ClientOrderCreate';
import { ClientOrderEdit } from '../components/admin/client-order/ClientOrderEdit';
import { ClientOrderList } from '../components/admin/client-order/ClientOrderList';
import { TermsAndConditionsCreate } from '../components/admin/termsAndConditions/TermsAndConditionsCreate';
import { TermsAndConditionsEdit } from '../components/admin/termsAndConditions/TermsAndConditionsEdit';
import { TermsAndConditionsList } from '../components/admin/termsAndConditions/TermsAndConditionsList';
import { ClientTermsAndConditionsList } from '../components/admin/clientTermsAndConditions/clientTermsAndConditionsList';

const httpClient = async (originalUrl: any, options: any = {}) => {
  // eslint-disable-line
  let url = originalUrl;
  // handle range-to-cursor pagination
  if (url.includes('api/payments')) {
    url = originalUrl.replace('api/payments', 'billing/payments');
    const urlParams = new URLSearchParams(url);
    const rawRange = urlParams.get('range');
    if (rawRange) {
      const range = JSON.parse(rawRange);
      urlParams.set('limit', (range[1] - range[0] + 1).toString());
      urlParams.delete('range');
      url = `${url.split('?')[0]}?${urlParams.toString()}`;
    }
  }

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  const session = await Auth.currentSession();
  const token = session.getAccessToken().getJwtToken();
  options.headers.set('Authorization', `Bearer ${token}`);
  try {
    const { status, headers, body, json } = await fetchUtils.fetchJson(
      url,
      options
    );
    let finalJson = json;
    if (json?.items) {
      finalJson = json.items;
    }
    return { status, headers, body, json: finalJson };
  } catch (error) {
    const httpError = error as HttpError;
    throw new Error(`${httpError.status} - ${httpError.message}`);
  }
};

const dataProvider = simpleRestProvider(
  `${process.env.REACT_APP_API_URL}/api`,
  httpClient
);

const NomadAdmin = () => {
  const theme = useTheme();

  return (
    <Admin
      layout={AdminLayout}
      theme={theme}
      basename="/admin"
      dashboard={Dashboard}
      dataProvider={dataProvider}
    >
      <Resource
        name="clients"
        list={ClientList}
        edit={ClientEdit}
      />
      <Resource
        name="charge-rates"
        list={ChargeRateList}
        create={ChargeRateCreate}
        edit={ChargeRateEdit}
      />
      <Resource
        name="charge-rate-values"
        list={ChargeRateValueList}
        create={ChargeRateValueCreate}
        edit={ChargeRateValueEdit}
      />
      <Resource
        name="locals"
        list={LocalList}
        create={LocalCreate}
        edit={LocalEdit}
      />
      <Resource
        name="opening-hours"
        list={OpeningHourList}
        create={OpeningHourCreate}
        edit={OpeningHourEdit}
      />
      <Resource
        name="products"
        list={ProductList}
        create={ProductCreate}
        edit={ProductEdit}
      />
      <Resource
        name="pack-components"
        list={PackComponentList}
        create={PackComponentCreate}
        edit={PackComponentEdit}
      />
       <Resource
        name="client-orders"
        list={ClientOrderList}
        create={ClientOrderCreate}
        edit={ClientOrderEdit}
      />
      <Resource
        name="orders"
        list={OrderList}
        create={OrderCreate}
        edit={OrderEdit}
      />
      <Resource
        name="order-details"
        list={OrderDetailList}
        create={OrderDetailCreate}
        edit={OrderDetailEdit}
      />
      <Resource
        name="order-types"
        list={OrderTypeList}
      />
      <Resource
        name="drivers"
        list={DriverList}
        create={DriverCreate}
        edit={DriverEdit}
      />
      <Resource
        name="journeys"
        list={JourneyList}
        create={JourneyCreate}
        edit={JourneyEdit}
      />
      <Resource
        name="shipments"
        list={ShipmentList}
        create={ShipmentCreate}
        edit={ShipmentEdit}
      />
      <Resource
        name="receptions"
        list={ReceptionList}
        create={ReceptionCreate}
        edit={ReceptionEdit}
      />
      <Resource
        name="reception-details"
        list={ReceptionDetailList}
        create={ReceptionDetailCreate}
        edit={ReceptionDetailEdit}
      />
      <Resource
        name="integrations"
        list={IntegrationList}
        create={IntegrationCreate}
        edit={IntegrationEdit}
      />
      <Resource
        name="pos"
        list={PointOfSaleList}
        edit={PointOfSaleEdit}
      />
      <Resource
        name="point-of-sale-products"
        list={PointOfSaleProductList}
        create={PointOfSaleProductCreate}
        edit={PointOfSaleProductEdit}
      />
      <Resource
        name="communes"
        list={CommuneList}
        create={CommuneCreate}
        edit={CommuneEdit}
      />
      <Resource
        name="regions"
        list={RegionList}
        create={RegionCreate}
        edit={RegionEdit}
      />
      <Resource
        name="countries"
        list={CountryList}
        create={CountryCreate}
        edit={CountryEdit}
      />
      <Resource
        name="stock-moves"
        list={StockMoveList}
        create={StockMoveCreate}
        edit={StockMoveEdit}
      />
      <Resource
        name="client-locals"
        list={ClientLocalList}
        create={ClientLocalCreate}
        edit={ClientLocalEdit}
      />
      <Resource
        name="payments"
        list={PaymentList}
        create={PaymentCreate}
        edit={PaymentEdit}
      />
      <Resource
        name="users"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
      />

      <Resource
        name="connections"
        list={ConnectionList}
        create={ConnectionCreate}
        edit={ConnectionEdit}
      />

      <Resource
        name="roles"
        list={RoleList}
        create={RoleCreate}
        edit={RoleEdit}
      />

      <Resource
        name="permissions"
        list={PermissionList}
        create={PermissionCreate}
        edit={PermissionEdit}
      />

      <Resource
        name="role-permissions"
        list={RolePermissionList}
        create={RolePermissionCreate}
        edit={RolePermissionEdit}
      />

      <Resource
        name="client-couriers"
        list={ClientCourierList}
        create={ClientCourierCreate}
        edit={ClientCourierEdit}
      />

      <Resource
        name="terms-and-conditions"
        list={TermsAndConditionsList}
        create={TermsAndConditionsCreate}
        edit={TermsAndConditionsEdit}
      />
      <Resource
        name="client-terms-and-conditions"
        list={ClientTermsAndConditionsList}
      />

      <CustomRoutes>
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/massive" element={<Massive />} />
        <Route path="/webhooks" element={<Webhooks />} />
        <Route path="/pos-locals" element={<POSLocals />} />
      </CustomRoutes>
    </Admin>
  );
};

export default NomadAdmin;
