import { useContext, useRef, useState } from 'react';
import { IClientOnboardingBody } from '../../interfaces';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { Switch, Typography } from '@mui/material';
import { rutFormatter } from '../../helpers/rutFormatter';

const CreateUser = () => {
  // Input refs
  // Basics Nomad
  const nameInput = useRef<HTMLInputElement>(null);
  const prefixInput = useRef<HTMLInputElement>(null);
  const emailInput = useRef<HTMLInputElement>(null);
  const nidInput = useRef<HTMLInputElement>(null);
  // Boolean Configs
  const [canChangeStockInput, setCanChangeStockInput] = useState<boolean>(true);
  const [makesDocumentInput, setMakesDocumentInput] = useState<boolean>(true);
  const [usesPickupWhatsappInput, setUsesPickupWhatsappInput] = useState<boolean>(true);
  const [usesExpressWhatsappInput, setUsesExpressWhatsappInput] = useState<boolean>(true);
  const [usesSameDayWhatsappInput, setUsesSameDayWhatsappInput] = useState<boolean>(true);
  const [hasB2BInput, setHasB2BInput] = useState<boolean>(false);
  // Numeric Configs
  const withdrawalLimitInput = useRef<HTMLInputElement>(null);
  const pickupWithdrawalWindowInput = useRef<HTMLInputElement>(null);
  const targetDaysSalesInput = useRef<HTMLInputElement>(null);
  const tarifierFeeInput = useRef<HTMLInputElement>(null);
  const packagingFeeInput = useRef<HTMLInputElement>(null);
  const freeShipmentPriceInput = useRef<HTMLInputElement>(null);
  // Basics Bsale
  const companyInput = useRef<HTMLInputElement>(null);
  const activityInput = useRef<HTMLInputElement>(null);
  const cityInput = useRef<HTMLInputElement>(null);
  const communeInput = useRef<HTMLInputElement>(null);
  const addressInput = useRef<HTMLInputElement>(null);
  const firstNameInput = useRef<HTMLInputElement>(null);
  const lastNameInput = useRef<HTMLInputElement>(null);

  // Context
  const { context, functionsApi } = useContext(GlobalContext);
  const { startLoading, finishLoading } = context;

  function reset() {
    (nameInput.current as HTMLInputElement).value = '';
    (prefixInput.current as HTMLInputElement).value = '';
    (emailInput.current as HTMLInputElement).value = '';
    (nidInput.current as HTMLInputElement).value = '';
    setCanChangeStockInput(true);
    setMakesDocumentInput(true);
    setUsesPickupWhatsappInput(false);
    setUsesExpressWhatsappInput(true);
    setUsesSameDayWhatsappInput(true);
    setHasB2BInput(false);
    (withdrawalLimitInput.current as HTMLInputElement).value = '';
    (pickupWithdrawalWindowInput.current as HTMLInputElement).value = '';
    (targetDaysSalesInput.current as HTMLInputElement).value = '';
    (tarifierFeeInput.current as HTMLInputElement).value = '';
    (packagingFeeInput.current as HTMLInputElement).value = '';
    (freeShipmentPriceInput.current as HTMLInputElement).value = '';
    (companyInput.current as HTMLInputElement).value = '';
    (activityInput.current as HTMLInputElement).value = '';
    (cityInput.current as HTMLInputElement).value = '';
    (communeInput.current as HTMLInputElement).value = '';
    (addressInput.current as HTMLInputElement).value = '';
    (firstNameInput.current as HTMLInputElement).value = '';
    (lastNameInput.current as HTMLInputElement).value = '';
  }

  const handleSubmit = async () => {
    try {
      if (
        !nameInput.current?.value ||
        !prefixInput.current?.value ||
        !emailInput.current?.value ||
        !nidInput.current?.value ||
        !withdrawalLimitInput.current?.value ||
        !pickupWithdrawalWindowInput.current?.value ||
        !targetDaysSalesInput.current?.value ||
        !companyInput.current?.value ||
        !activityInput.current?.value ||
        !cityInput.current?.value ||
        !communeInput.current?.value ||
        !addressInput.current?.value ||
        !firstNameInput.current?.value ||
        !lastNameInput.current?.value
      ) {
        throw new Error('Datos inválidos: faltan campos.');
      }

      startLoading();

      const data: IClientOnboardingBody = {
        name: nameInput.current.value,
        prefix: prefixInput.current.value,
        email: emailInput.current.value,
        nid: nidInput.current.value,
        canChangeStock: canChangeStockInput,
        makesDocument: makesDocumentInput,
        usesPickupWhatsapp: usesPickupWhatsappInput,
        usesExpressWhatsapp: usesExpressWhatsappInput,
        usesSameDayWhatsapp: usesSameDayWhatsappInput,
        hasB2B: hasB2BInput,
        withdrawalLimit: Number(withdrawalLimitInput.current.value),
        pickupWithdrawalWindow: Number(pickupWithdrawalWindowInput.current.value),
        targetDaysSales: Number(targetDaysSalesInput.current.value),
        tarifierFee: Number(tarifierFeeInput.current?.value) || null,
        packagingFee: Number(packagingFeeInput.current?.value) || null,
        freeShipmentPrice: Number(freeShipmentPriceInput.current?.value) || null,
        company: companyInput.current.value,
        activity: activityInput.current.value,
        city: cityInput.current.value,
        commune: communeInput.current.value,
        address: addressInput.current.value,
        firstName: firstNameInput.current.value,
        lastName: lastNameInput.current.value,
      };

      await functionsApi.onboarding({ type: 'client', data });
      alert('success', 'El cliente se ha creado correctamente.');
      reset();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      } else {
        alert('error', 'No se pudo crear el cliente.');
      }
    }
    finishLoading();
  };

  const handleNidChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = rutFormatter(e.target.value);
    if (nidInput.current) {
      nidInput.current.value = (formattedValue);
    }
  };

  return (
    <>
      <Typography variant="h6" component="div" sx={{ marginTop: 2, fontWeight: 'bold' }}>
        Datos Nomad
      </Typography>
      <div className="InputWrapper">
        <div className="InputTitle">Nombre</div>
        <input type="text" name="name" ref={nameInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Prefix</div>
        <input type="text" name="prefix" ref={prefixInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Email</div>
        <input type="text" name="email" ref={emailInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">NID</div>
        <input type="text" name="nid" ref={nidInput} onChange={handleNidChange} />
      </div>

      <Typography variant="h6" component="div" sx={{ marginTop: 2, fontWeight: 'bold' }}>
        Datos Bsale
      </Typography>

      <div className="InputWrapper">
        <div className="InputTitle">Empresa</div>
        <input type="text" name="company" ref={companyInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Actividad</div>
        <input type="text" name="activity" ref={activityInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Ciudad</div>
        <input type="text" name="city" ref={cityInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Comuna</div>
        <input type="text" name="commune" ref={communeInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Dirección</div>
        <input type="text" name="address" ref={addressInput} />
        <div className="InputDescription">
          Calle y Numeración. Ej: Av. Test 1234
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Nombre Representante</div>
        <input type="text" name="nid" ref={firstNameInput} />
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">Apellido Representante</div>
        <input type="text" name="nid" ref={lastNameInput} />
      </div>

      <Typography variant="h6" component="div" sx={{ marginTop: 2, fontWeight: 'bold' }}>
        Configuraciones Nomad
      </Typography>

      <div className="InputWrapper">
        <div className="InputTitle">
          canChangeStock
          <Switch
            checked={canChangeStockInput}
            onClick={() => setCanChangeStockInput(!canChangeStockInput)}
          />
        </div>
        <div className="InputDescription">
          Cliente actualiza stock desde Nomad hacia sus POS
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">
          makesDocument
          <Switch
            checked={makesDocumentInput}
            onClick={() => setMakesDocumentInput(!makesDocumentInput)}
          />
        </div>
        <div className="InputDescription">
          Cliente genera documentos a través del Bsale de Nomad
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">
          usesPickupWhatsapp
          <Switch
            checked={usesPickupWhatsappInput}
            onClick={() => setUsesPickupWhatsappInput(!usesPickupWhatsappInput)}
          />
        </div>
        <div className="InputDescription">
          Cliente envía Whatsapp en flujos de Pedidos pickup
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">
          usesExpressWhatsapp
          <Switch
            checked={usesExpressWhatsappInput}
            onClick={() => setUsesExpressWhatsappInput(!usesExpressWhatsappInput)}
          />
        </div>
        <div className="InputDescription">
          Cliente envía Whatsapp en flujos de Pedidos express
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">
          usesSameDayWhatsapp
          <Switch
            checked={usesSameDayWhatsappInput}
            onClick={() => setUsesSameDayWhatsappInput(!usesSameDayWhatsappInput)}
          />
        </div>
        <div className="InputDescription">
          Cliente envía Whatsapp en flujos de Pedidos same-day
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">
          hasB2B
          <Switch
            checked={hasB2BInput}
            onClick={() => setHasB2BInput(!hasB2BInput)}
          />
        </div>
        <div className="InputDescription">
          Cliente tiene B2B en Nomad
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">withdrawalLimit</div>
        <input type="number" name="withdrawalLimit" ref={withdrawalLimitInput} defaultValue={5} />
        <div className="InputDescription">
          Límite mensual de retiros tipo WO desde Clients-Platform
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">pickupWithdrawalWindow</div>
        <input type="number" name="pickupWithdrawalWindow" ref={pickupWithdrawalWindowInput} defaultValue={30} />
        <div className="InputDescription">
          Límite de tiempo para retirar Pedidos pickup fulfilled
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">targetDaysSales</div>
        <input type="number" name="targetDaysSales" ref={targetDaysSalesInput} defaultValue={15} />
        <div className="InputDescription">
          Intervalo de tiempo para re abastecimiento (Reportería)
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">tarifierFee</div>
        <input type="number" name="tarifierFee" ref={tarifierFeeInput} />
        <div className="InputDescription">
          Cobro fijo por tarificador (Opcional)
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">packagingFee</div>
        <input type="number" name="packagingFee" ref={packagingFeeInput} />
        <div className="InputDescription">
          Cobro fijo por paquetes (Opcional)
        </div>
      </div>

      <div className="InputWrapper">
        <div className="InputTitle">freeShipmentPrice</div>
        <input type="number" name="freeShipmentPrice" ref={freeShipmentPriceInput} />
        <div className="InputDescription">
          Cobro fijo por envío gratis (Opcional)
        </div>
      </div>

      <div
        className="Button"
        onClick={handleSubmit}
      >
        Enviar
      </div>

      <Typography variant="h6" component="div" sx={{ marginTop: 2, fontWeight: 'bold' }}>
        Importante
      </Typography>

      <div className="InputWrapper">
        <div className="InputDescription">Se creará el Cliente en Nomad y Bsale y sus POS Admin y Clients</div>
        <div className="InputDescription">Crear ClientCouriers y ChargeRates posteriormente</div>
      </div>
    </>
  );
};

export default CreateUser;
