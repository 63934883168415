import {
  Datagrid,
  DateField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const clientTermsAndConditionsFilters = [
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <NumberInput label="Id" source="id" alwaysOn />,
  <ReferenceInput
    source="TermsAndConditionsId"
    reference="terms-and-conditions"
    sort={{ field: 'id', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="version" />
  </ReferenceInput>,
];

export const ClientTermsAndConditionsList = () => (
  <List
    filters={clientTermsAndConditionsFilters}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<AdminPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="TermsAndConditionsId" reference="terms-and-conditions">
        <TextField source="version" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
